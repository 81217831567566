<template>
    <div class="wrapper mt-0" style="height:100%;">
        <div class="contentMediatheque" style="height:100%; ">
            <MediathequeComponent style="border-top:1px solid #d2d2d2 !important;" />
        </div>
    </div>
</template>

<script>
import MediathequeComponent from '@/components/mediathequeComponent/mediathequeComponent.vue'
export default {
    name:"Mediatheque",
    components: {MediathequeComponent},
    data() {
        return {}
    },
    methods: {},
    computed: {},
    mounted() {},
}
</script>
<style lang="scss">
.contentMediatheque {
   //margin-top:10px;
}
</style>